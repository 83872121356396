// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { addToUserList, removeFromUserList, 
         setSpeakerState, setCardSide } from './actions';

import iconSpeaker from "./assets/img/speaker.png"
import iconNoSpeaker from "./assets/img/nospeaker.png"
import iconRemove from "./assets/img/emptystar.png"
import iconAdd from "./assets/img/star.png"
import iconReplay from "./assets/img/replay.png"

export default function Card({ card }: any, isPlaying: boolean) {

  const dispatch = useDispatch();
  const [isAdded, setIsAdded] = useState(false);
  const [voicesLoaded, setVoicesLoaded] = useState(false);

  const userList = useSelector((state: any) => state.app.userList);
  const speaker = useSelector((state: any) => state.app.speakerState) ?? true;
  let side = useSelector((state: any) => state.app.cardSide) ?? false;

  useEffect(() => {
    // Check if the card is in the userList and update isAdded accordingly
    const cardIsAdded = userList.some((userCard: any) => userCard.fields.side1 === card.fields.side1);
    setIsAdded(cardIsAdded);
  }, [userList, card]);

  const side1Length = card.fields.side1.length;
  const side2Length = card.fields.side2.length;
  // const maxLength = Math.max(side1Length, side2Length);

  const getFontSize = () => {
    if (side1Length > 3) {
      return "small-font";
    } else if (side1Length > 2) {
      return "medium-font";
    } else {
      return "large-font";
    }
  };


  // Font class based on cardType
  const getFontClassForSide2 = () => {
    const cardType = card.type || "字"; // Default to "字" if cardType is undefined

    switch (cardType) {
      case "注音":
        return "frontfont";
      default:
        return "backfont";
    }
  };
  

  const getVoices = () => {
    return new Promise((resolve) => {
      let voices = speechSynthesis.getVoices()
      if (voices.length) {
        resolve(voices)
        return
      }
      speechSynthesis.onvoiceschanged = () => {
        voices = speechSynthesis.getVoices()
        resolve(voices)
      }
    })
  }

  useEffect(() => {
    const loadVoices = async () => {
      const allVoices = await getVoices();
      setVoicesLoaded(true);
    };
    loadVoices();
  }, []);


  const speakTTS = async () => {

    let side1phrase = card.fields.side1p ? card.fields.side1p : card.fields.side1;
    let side2phrase = card.fields.side2p ? card.fields.side2p : card.fields.side2b;
        
    const cardType = card.type ? card.type : "字";
    const cardlang = card.lang ? card.lang : "zh-TW";

    if ( cardType == "部首" ) {
      side2phrase = side2phrase + "字部";
    } else {
      side2phrase = side2phrase + "的" + side1phrase;
    }

    if ( cardType == "天干" ) {
      side2phrase = side1phrase;
    }
    console.log("cardtype =",cardType);

    if (cardlang.endsWith(".mp3")) {
    const mp3name = !side ? cardlang.split(".")[0] : `${cardlang.split(".")[0]}b`;
    import(`./assets/mp3/${mp3name}.mp3`)
      .then((audioModule) => {
        // Now, audioModule will contain the dynamically imported module
        let audio = new Audio(audioModule.default);
        audio.play();
      })
      .catch((error) => {
        console.error("Error loading audio file:", error);
      });
    }
    else {
      const message = new SpeechSynthesisUtterance();
//      message.text = !side ? side1phrase : side2phrase;
//      message.text = !side ? side2phrase : side1phrase + ". , . , . , . , ." + side2phrase ;
//      message.text = !side ? side2phrase : side2phrase ;
      message.text = !side ? side2phrase : side1phrase ;
      message.volume = 1; // Volume range = 0 - 1
      message.rate = 0.8; // Speed of the text read , default 1
      message.lang = cardlang; // Language, default 'zh-TW'
      speechSynthesis.speak(message);
    }
  }

  useEffect(() => {
    if (voicesLoaded && speaker) {
      speakTTS();
    }
  }, [voicesLoaded, speaker, side]);

  function handleClick() {
    dispatch(setCardSide(!side));
  }

  function handleSpeakerClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    dispatch(setSpeakerState());
  }

  function handleReplayClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    speakTTS();
  }


  function handleAddRemoveClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    if (isAdded) {
      dispatch(removeFromUserList(card));
    } else {
      dispatch(addToUserList(card));
    }
    setIsAdded(!isAdded);
  }

  return (
    <div className={`card ${side ? "side" : ""}`} onClick={handleClick}>
      <div className="card-top">
        <button onClick={handleAddRemoveClick} className="add-remove-button">
          <img src={!isAdded ? iconRemove : iconAdd} alt={isAdded ? "Remove" : "Add"} />
        </button>      
        <button onClick={handleSpeakerClick} className="pronounce-button">
          <img src={speaker ? iconSpeaker : iconNoSpeaker} alt="說" />
        </button>
        <button onClick={handleReplayClick} className="replay-button">
          <img src={iconReplay} alt="再說" />
        </button>
      </div>
      <div className={`front ${getFontSize()}`}>{card.fields.side1}</div>
      <div className={`back ${getFontSize()} ${getFontClassForSide2()}`}>
        <div>{card.fields.side2}</div>
        <div className="doublefont">{card.fields.side2}</div>
        <div className="bottomalign backfont"><hr></hr>{card.fields.side2b}</div>
      </div>
    </div>
  );
}

